import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useIsMobile from "states/deviceState";
import { TYPO } from "styles/Typo";
import { KOREA } from "utils/constant/ITME_INFO";
import { toDetailPage } from "utils/navigateToDetailPage";
import { CheckFill } from "../../Detail/AI/Chart";
import { IndexSkeleton } from "components/skeleton/main";

function IndexList({ indexList }: { indexList: any }) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const handleWheel = (event: any) => {
    event.currentTarget.scrollLeft += event.deltaY;
  };

  if (!indexList || indexList?.length === 0)
    return (
      <div className="bg-white">
        <IndexSkeleton />
      </div>
    );

  return (
    <div className="bg-white">
      <div
        className="overflow-x-scroll scroll"
        onWheel={isMobile ? undefined : handleWheel}
      >
        <div className="px-6 py-4 flex items-center w-fit ">
          {indexList.map((index: any, i: number) => (
            <div
              key={i}
              className={`flex items-center cursor-pointer ${
                index.EW_SIGN_YN === 1 ? "gap-2" : ""
              }  shrink-0`}
              onClick={() => {
                toDetailPage(router, index.ITEM_CD_DL);
              }}
            >
              <div
                className={`flex flex-col  ${
                  i === 0 ? "pr-3" : i < indexList.length - 1 ? "px-3" : "pl-3"
                }`}
              >
                <span
                  className={`${TYPO.TYPO7_1_MD} text-gray50 whitespace-nowrap`}
                >
                  {router.locale === "ko"
                    ? index.ITEM_KR_NM
                    : index.ITEM_ENG_NM}
                </span>
                <div className="flex items-center gap-1 ">
                  <span className={`${TYPO.TYPO7_SB} text-gray80`}>
                    {index?.LOC === KOREA
                      ? index.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : index.ADJ_CLOSE_USD.toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </span>
                  <span
                    className={`${TYPO.TYPO7_MD} ${
                      Number(index.ADJ_CLOSE_CHG.toFixed(2)) === 0
                        ? "text-gray60"
                        : Number(index.ADJ_CLOSE_CHG.toFixed(2)) > 0
                        ? "text-red40"
                        : "text-blue40"
                    }`}
                  >
                    {/**수익률 */}
                    {Number(index.ADJ_CLOSE_CHG.toFixed(2)) > 0 ? "+" : ""}
                    {index.ADJ_CLOSE_CHG.toFixed(2)}%
                  </span>
                </div>
              </div>
              {index.EW_SIGN_YN === 1 && <CheckFill />}
              {i < indexList.length - 1 && (
                <div className="w-[1px] bg-gray10 h-[48px] shrink-0"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default IndexList;
