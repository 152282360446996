import { TYPO } from "styles/Typo";
import OngoingDiscussMain from "./OngoingDiscussMain";
import { MainDiscussSkeleton } from "components/skeleton/main";
import { useOngoingDiscuss } from "utils/clientAPIs/discuss";

function MainDiscussIndex({ isMobile }: { isMobile: boolean }) {
  const [onGoingDiscussList, isLoading] = useOngoingDiscuss();

  if (isLoading) null;
  //   {
  //   return (
  //     <div>
  //       <MainDiscussSkeleton />
  //     </div>
  //   );
  // }

  if (!onGoingDiscussList || onGoingDiscussList.length === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col bg-white gap-4 ">
      {/* <div className="px-6 pt-6">
        <span className={`${TYPO.TYPO4_SB} text-gray90`}>토론</span>
      </div> */}
      <div className="flex flex-col gap-4 py-4 px-5">
        <OngoingDiscussMain
          // key={index}
          data={onGoingDiscussList?.[0]}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}

export default MainDiscussIndex;
