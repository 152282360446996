import useSWR from "swr";
import { fetcher } from "./fetcher";
import { useRecoilState } from "recoil";
import { detailLoadingState } from "states/detailLoading";
import { KOREA, US } from "utils/constant/ITME_INFO";
import * as Sentry from '@sentry/nextjs';
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import usePriority from "./priority";

export const useDetailData = (ticker: string | undefined) => {

    // 주요 데이터 요청
    const { data: detailData, isValidating: isDetailLoading } = useSWR(
        ticker ? `/api/detail/${ticker}` : null,
        fetcher
    );

    // 정지 상태 여부 데이터 요청
    const { data: haltData, isValidating: isHaltLoading } = useSWR(
        detailData ? `/api/haltyn?itemCode=${detailData[0]?.ITEM_CD}` : null,
        fetcher
    );


    // 총점 데이터 score 추가
    const { data: totalData, isValidating: isTotalLoading } = useSWR(
        detailData ? `/api/detail/score?ticker=${ticker}&itemCode=${detailData[0]?.ITEM_CD}` : null,
        fetcher
    );


    // useEffect(() => {
    //     if (!isTotalLoading) {
    //         if (!totalData?.TOTAL_PER || !totalData?.TOTAL_LV || !totalData?.TOTAL_LV_KR || !totalData?.BASE_DT || !totalData?.MOMENTUM || !totalData?.SHARPE_RATIO || !totalData?.FINANCIAL) {
    //             // Sentry에 에러 전송
    //             Sentry.captureMessage(`Detail TOTAL SCORE DATA ERROR`, {
    //                 level: 'error',
    //                 tags: {
    //                     errorType: 'TOTAL SCORE', // 나중에 필터링할 태그
    //                 },
    //                 extra: { data: totalData },
    //             });
    //         }
    //     }
    // }, [isTotalLoading]);



    // 모든 데이터 로딩 상태 및 가공된 데이터 반환
    const isLoading = isDetailLoading || isHaltLoading || isTotalLoading;
    const isHalt = haltData?.isHalt;
    const Cat = detailData?.[0]?.CAT;
    const Loc = detailData?.[0]?.LOC;

    // 유효한 Score 여부 체크
    const isValidScoreStock = (isDetailLoading || isHaltLoading) ? false :
        Cat === 'Index' || isHalt || !(Cat === 'Stock' && (Loc === KOREA || Loc === US)); // 

    return {
        detailData: detailData?.[0],
        scoreData: totalData,
        prevDayData: detailData?.[1],
        // isHoliday: detailData?.[4]?.isHoliday,
        // riskTargetDate: detailData?.[4]?.riskTargetDate,
        isLoading,
        isHalt,
        isValidScoreStock,
    };
};


export const useFinanceData = (ticker: string | undefined, ITEM_CD: number, enable = true,): [any, boolean] => {
    const router = useRouter();
    const { data, isValidating } = useSWR(
        enable ? `/api/detail/fin?ticker=${ticker}&score=true&itemCode=${ITEM_CD}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;

    useEffect(() => {
        if (data) {
            // null인 값을 체크하려는 속성명을 사용하세요. 예: data.someField가 null인 경우
            if (!data?.isUsdData || !data?.finScore || !data?.REPT_PERIOD || data?.values?.length === 0) {
                // Sentry에 에러 전송
                Sentry.captureMessage(`Detail FIN DATA ERROR`, {
                    level: 'error',
                    tags: {
                        errorType: 'FIN', // 나중에 필터링할 태그
                    },
                    extra: { data },
                });
                // router.push("/_error")
            }
        }
    }, [data]);

    return [data, isValid];
}
export const useIndustryMMTData = (ticker: string | undefined, ITEM_CD: number, enable = true): [any, boolean] => {
    const router = useRouter();

    const { data, isValidating } = useSWR(
        enable ? `/api/detail/momentum?ticker=${ticker}&score=true&itemCode=${ITEM_CD}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    // console.log("data:", data)

    useEffect(() => {
        if (data) {
            // null인 값을 체크하려는 속성명을 사용하세요. 예: data.someField가 null인 경우
            if (!data?.MON_PER || !data?.industry || !data?.BASE_DT || !data?.standardChg || !data?.MOM_SCORE_1M) {
                // Sentry에 에러 전송
                Sentry.captureMessage(`Detail MMT DATA ERROR`, {
                    level: 'error',
                    tags: {
                        errorType: 'MMT', // 나중에 필터링할 태그
                    },
                    extra: { data },
                });
                // router.push("/_error")
            }
        }
    }, [data]);


    return [data, isValid];

}
export const useSharpeData = (ticker: string | undefined, ITEM_CD: number, enable = true): [any, boolean] => {
    const router = useRouter();

    const { data, isValidating } = useSWR(
        enable ? `/api/detail/sharpe?ticker=${ticker}&score=true&itemCode=${ITEM_CD}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    useEffect(() => {
        if (data) {
            // null인 값을 체크하려는 속성명을 사용하세요. 예: data.someField가 null인 경우
            if (!data?.BASE_DT || !data?.EXP_CVaRET_95 || !data?.EXP_CVaRNTS_95 || !data?.yearlyData || !data?.RAR_PER || !data?.CVaR_LV) {
                // Sentry에 에러 전송
                Sentry.captureMessage(`Detail SHARPE DATA ERROR`, {
                    level: 'error',
                    tags: {
                        errorType: 'SHARPE', // 나중에 필터링할 태그
                    },
                    extra: { data },
                });
                // router.push("/_error")

            }
        }
    }, [data]);
    return [data, isValid];
}

export const useHighScoreStockList = (type: string, locKorean: boolean, enable = true) => {

    const { data, isValidating } = useSWR(
        enable && type ? `/api/detail/highstock?type=${type}&iskorean=${locKorean}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    return [data, isValid];
}
export const useHighTotalScoreStockList = ({ limit, loc, isDetail }: { limit?: number, loc?: string, isDetail?: boolean }) => {
    const priority = usePriority(0.3);
    const { data, isValidating } = useSWR(
        priority || isDetail ? `/api/hightotalscore?limit=${limit}&loc=${loc || ''}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );

    const isValid = !data && isValidating;
    return [data, isValid];
}