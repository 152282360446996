import Button from "components/common/buttons/buttons";
import List from "components/common/List/list";
import Tranding from "components/templates/m/main/Trending";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";
import useTrendingAsset from "utils/clientAPIs/trending";
import { KOREA } from "utils/constant/ITME_INFO";
import { toDetailPage } from "utils/navigateToDetailPage";
import InterestStar from "../../components/InterestStar";
import useMainStockList from "utils/clientAPIs/MainStockList";
import DividerButton from "components/common/divider/button";
import { useTrendingIndustry } from "utils/clientAPIs/industry";
import moment from "moment-timezone";
import Img from "components/common/List/img";
import Text from "components/common/List/Text";
import { TrendIndustrySkeleton } from "components/skeleton/main";
function HighMomentum({}: {}) {
  const router = useRouter();
  const hour = moment().tz("Asia/Seoul").hour();
  const minute = moment().tz("Asia/Seoul").minute();
  // 한국 시간 기준으로 6시 30분 이후~ 오후 6시 30분 이전
  const isKoreaTime =
    (hour === 6 && minute >= 30) ||
    (hour === 18 && minute <= 30) ||
    (hour > 6 && hour < 18);

  const isKorean = isKoreaTime;

  const [data, isValid] = useTrendingIndustry({
    limit: 5,
  });
  const [activeIndustry, setActiveIndustry] = useState<number>(
    data?.[0]?.TU_CD
  ); // TU_CD로 변경

  useEffect(() => {
    setActiveIndustry(data?.[0]?.TU_CD);
  }, [data]);

  const industryNames =
    router.locale === "ko"
      ? data?.map((item: any) => item?.TU_NM)
      : data?.map((item: any) => item?.TU_ENG_NM);

  const currentAssets =
    data?.find((item: any) => item?.TU_CD === activeIndustry) || [];

  if (isValid)
    return (
      <div className="bg-white">
        <TrendIndustrySkeleton />
      </div>
    );

  if (data?.length === 0 || !data) return null;
  return (
    <div className="bg-white">
      <div className="flex flex-col w-full gap-2">
        <div className="px-6 pt-6">
          <span className={`${TYPO.TYPO4_SB} text-gray80`}>요즘 뜨는 산업</span>
        </div>
        <div className="flex flex-col">
          <div className={"flex flex-col gap-6 relative pt-2 bg-white "}>
            <div className="flex flex-col gap-2 w-full pb-4">
              <div className={`w-full `}>
                <div className="flex gap-2 overflow-auto scroll px-5 h-[32px]">
                  {data?.length > 0 && !isValid
                    ? data?.slice(0, 9).map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              // setActiveIndustry(index);
                              setActiveIndustry(item?.TU_CD);
                            }}
                            className={`flex items-center justify-center px-3 py-[6px] ${
                              TYPO.TYPO7_1_MD
                            } ${
                              item?.TU_CD === activeIndustry
                                ? "text-gray80 bg-gray10 rounded-[8px]"
                                : "text-gray50"
                            } text-gray50 shrink-0 cursor-pointer`}
                          >
                            {industryNames[index]}
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul>
          {currentAssets?.assets?.map((asset: any, index: number) => (
            <li
              key={index}
              className={` w-full flex items-center  px-6 py-2  gap-3 bg-white cursor-pointer `}
              onClick={() => router.push(`/detail/${asset.ITEM_CD_DL}`)}
            >
              <div className="justify-center items-center inline-flex">
                <span
                  className={` text-center text-blue40  text-[15px] font-semibold leading-none whitespace-nowrap`}
                >
                  {`${asset?.TOTAL_PER}점`}
                </span>
              </div>
              <Img
                src={
                  `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
                  "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
                }
                HR_ITEM_NM={""}
              />

              <div
                className={`justify-start grow  overflow-hidden whitespace-pre-wrap flex-col  items-start inline-flex `}
              >
                <Text
                  item={
                    router.locale === "ko"
                      ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                      : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
                  }
                  price={
                    router.locale === "ko"
                      ? asset.ADJ_CLOSE_KRW &&
                        asset.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) + (asset.CAT !== "Index" ? "원" : "")
                      : asset.ADJ_CLOSE &&
                        asset.ADJ_CLOSE.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) +
                          (asset.CAT !== "Index"
                            ? asset?.LOC === KOREA
                              ? "₩"
                              : "$"
                            : "")
                  }
                  percent={asset.ADJ_CLOSE_CHG.toLocaleString("en-us", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                />
              </div>

              <div className="flex items-center shrink-0 justify-end">
                <div>{<InterestStar asset={asset} size={30} />}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <DividerButton
        text={"더 보기"}
        onClick={() => {
          router.push(`/industryranking?loc=${isKorean ? "korea" : "us"}`);
        }}
      />
    </div>
  );
}

export default HighMomentum;
