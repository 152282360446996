import useSWR from "swr";
import { fetcher } from "./fetcher";
import { getSession, useSession } from "next-auth/react";
import * as Sentry from "@sentry/nextjs";

export const useDiscussListAll = (): [any, boolean] => {
    const { data: session }: any = useSession();
    const { data, isValidating } = useSWR(
        session?.user?.email ? `/api/discuss/list?email=${session?.user?.email}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = (!data && isValidating) || !session;

    return [data, isValid];
}

export const useOngoingDiscuss = (): [any, boolean] => {
    // const { data: session }: any = useSession();

    const { data, isValidating } = useSWR(
        `/api/discuss/list?ongoing=true`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    return [data, isValid];
}

export const useIsUserVote = ({ discussId }: { discussId: number }): [any, boolean] => {
    const { data: session }: any = useSession();
    const { data, isValidating } = useSWR(
        session?.user?.email && discussId ? `/api/discuss/vote?email=${session?.user?.email}&discussId=${discussId}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    return [data, isValid];
}

export const useNotPublishDiscuss = (): [any, boolean] => {
    const { data, isValidating } = useSWR(
        `/api/discuss/list?admin=true`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    return [data, isValid];
}


export const useDiscussDetail = ({ discussId, setAnimation }: { discussId: number, setAnimation: any }): [any, boolean, any, any] => {
    const { data: session }: any = useSession();
    const { data, isValidating, mutate } = useSWR(
        session?.user?.email ? `/api/discuss/${discussId}?email=${session?.user?.email}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const submitVote = async (value: string, symbol: string, isAdminDiscuss?: boolean) => {
        const latestData = await mutate(); // 최신 데이터 가져오기
        const latestSession = await getSession(); // 최신 세션 가져오기
        if (!latestData || !latestSession?.user?.email) return;
        setAnimation(true);

        // 1. 즉시 결과 반영 (Optimistic UI)
        const updatedData = {
            ...latestData,
            userChoice: value,
            userChoiceSymbol: symbol,
            totalVotes: latestData.totalVotes + 1,
            options: latestData.options.map((option: any) => {
                if (option.value === value) {
                    return {
                        ...option,
                        votes: option.votes + 1
                    }
                }
                return option;
            }),
            // animation: true,
        }
        console.log("isAdminDiscuss", isAdminDiscuss);
        console.log("updatedData", updatedData);

        // 1초 대기
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(50);
        mutate(updatedData, false); // 즉시 UI 업데이트

        if (isAdminDiscuss) {
            // mutate(updatedData, false); // 즉시 UI 업데이트
        }

        // 2. API 요청 (비동기)
        try {
            const re = await fetch(`/api/discuss/vote`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    discussId,
                    email: latestSession.user.email,
                    symbol
                }),
            });
            // if (!isAdminDiscuss) {
            //     setAnimation(true);
            // }

            const status = re.status;
            if (status !== 200) {
                const error = await re.json();
                throw new Error(error?.message || "투표 실패");
            }

            // 3. 서버 데이터 동기화
            // setTimeout(() => {
            //     mutate(); // 서버 데이터로 재요청
            // }, 100);
            // mutate();

        } catch (error) {
            Sentry.captureException(`투표 실패: ${error}`);
            console.error("투표 실패", error);
            // mutate(latestData, false); // 실패 시 원래 데이터로 롤백
        }
    };



    const isValid = (!data && isValidating) || !session;
    return [data, isValid, submitVote, mutate];
}


// export const registerVote = async ({ discussId, value }: { discussId: number, value: string }) => {
//     const { data: session }: any = useSession();
//     if (!session) return null;
//     const res = await fetch(`/api/discuss/vote`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//             discussId,
//             email: session.user.email,
//             value
//         }),
//     });
//     return res.json();
// }