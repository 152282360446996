import useSWR from "swr";
import { fetcher } from "./fetcher";
import usePriority from "./priority";

export const useTrendingIndustry = ({ limit, loc, isDetail }: { limit?: number, loc?: string, isDetail?: boolean }) => {
    const priority = usePriority(1);

    const { data, isValidating } = useSWR(
        priority || isDetail ? `/api/trendingIndustry?limit=${limit}&loc=${loc || ''}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    return [data, isValid];
}