import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";
import { AlpabetIcon, OIcon, VoteIcon, XIcon } from "../Icons";
import { useRouter } from "next/router";

function OngoingDiscuss({
  data,
  clickable = true,
  addClassName = "",
}: {
  data: any;
  clickable?: boolean;
  addClassName?: string;
}) {
  const router = useRouter();
  const isOXOption = data.agendaType === "binary";

  return (
    <div
      className={`px-6 py-4 flex flex-col gap-2 ${
        clickable ? "cursor-pointer" : ""
      } ${addClassName}`}
      onClick={() => {
        if (clickable) {
          router.push(`/discuss/${data.id}`);
        }
      }}
    >
      {/* {isOXOption ? (
        <div className="flex gap-1 items-center">
          <OIcon size={12} />
          <XIcon size={12} />
        </div>
      ) : (
        <div className="flex gap-1 items-center">
          <AlpabetIcon size={12} alphabet="A" />
          <AlpabetIcon size={12} alphabet="B" />
        </div>
      )} */}
      <VoteIcon />
      <span
        className={`whitespace-pre-line ${TYPO.TYPO5_SB} text-gray80 break-keep break-words`}
      >
        {data.title}
      </span>
      <span className={`whitespace-pre-line ${TYPO.TYPO6_MD} text-gray60`}>
        {data.totalVotes.toLocaleString()}명 투표 중!
      </span>
    </div>
  );
}

export default OngoingDiscuss;
