import { useRouter } from "next/router";
import InterestStar from "../../components/InterestStar";
import Img from "components/common/List/img";
import Text from "components/common/List/Text";
import { KOREA } from "utils/constant/ITME_INFO";
import { useHighTotalScoreStockList } from "utils/clientAPIs/detailDiagnosis";
import DividerButton from "components/common/divider/button";
import { TYPO } from "styles/Typo";
import moment from "moment-timezone";
import { HighScoreStockSkeleton } from "components/skeleton/main";

function HighScoreList({}: {}) {
  const router = useRouter();
  const [data, loading, error] = useHighTotalScoreStockList({ limit: 5 });
  const hour = moment().tz("Asia/Seoul").hour();
  const minute = moment().tz("Asia/Seoul").minute();
  const isKoreaTime =
    (hour === 6 && minute >= 30) ||
    (hour === 18 && minute <= 30) ||
    (hour > 6 && hour < 18);

  const isKorean = isKoreaTime;
  const ListTemp = ({ asset }: { asset: any }) => {
    return (
      <li
        className={` w-full flex items-center  px-6 py-2  gap-3 bg-white cursor-pointer `}
        onClick={() => router.push(`/detail/${asset.ITEM_CD_DL}`)}
      >
        <div className="justify-center items-center inline-flex">
          <span
            className={` text-center text-blue40  text-[15px] font-semibold leading-none whitespace-nowrap`}
          >
            {`${asset?.TOTAL_PER}점`}
          </span>
        </div>
        <Img
          src={
            `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
            "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
          }
          HR_ITEM_NM={""}
        />

        <div
          className={`justify-start grow  overflow-hidden whitespace-pre-wrap flex-col  items-start inline-flex `}
        >
          <Text
            item={
              router.locale === "ko"
                ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
            }
            price={
              router.locale === "ko"
                ? asset.ADJ_CLOSE_KRW &&
                  asset.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }) + (asset.CAT !== "Index" ? "원" : "")
                : asset.ADJ_CLOSE &&
                  asset.ADJ_CLOSE.toLocaleString("en-us", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }) +
                    (asset.CAT !== "Index"
                      ? asset?.LOC === KOREA
                        ? "₩"
                        : "$"
                      : "")
            }
            percent={asset.ADJ_CLOSE_CHG.toLocaleString("en-us", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          />
        </div>

        <div className="flex items-center shrink-0 justify-end">
          <div>{<InterestStar asset={asset} size={30} />}</div>
        </div>
      </li>
    );
  };

  if (!data || data?.length === 0)
    return (
      <div className="bg-white">
        <HighScoreStockSkeleton />
      </div>
    );

  return (
    <div className="bg-white">
      {/* <HighTotalScoreStocks /> */}
      <div className="px-6 pt-6">
        <span className={`${TYPO.TYPO4_SB} text-gray80`}>
          투자 매력도 높은 주식
        </span>
      </div>
      <div className="py-2 flex flex-col gap-2">
        {data?.slice(0, 5).map((asset: any, index: number) => {
          return <ListTemp asset={asset} key={index} />;
        })}
      </div>
      <DividerButton
        text={`더 보기`}
        onClick={() => {
          // router.push("/")
          router.push(`/scoreranking?loc=${isKorean ? "korea" : "us"}`);
        }}
      />
    </div>
  );
}

export default HighScoreList;
