import useSWR from "swr";
import { fetcher } from "./fetcher";
import usePriority from "./priority";

export const useHotTopicList = ({ limit, isDetail }: { limit?: number, isDetail?: boolean }) => {
    const priority = usePriority(1);
    const { data, isValidating } = useSWR(
        priority || isDetail ? `/api/hotTopic?limit=${limit}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    return [data, isValid];
}