export const OIcon = ({ size }: { size?: number }) => {
  return (
    <div className="flex items-center justify-center p-[3px] w-fit h-fit rounded-[4px] bg-blue50">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size : "12"}
        height={size ? size : "12"}
        viewBox="0 0 12 12"
        fill="none"
      >
        <circle
          cx="6"
          cy="6"
          r="4"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
export const XIcon = ({ size }: { size?: number }) => {
  return (
    <div className="flex items-center justify-center p-[3px] w-fit h-fit rounded-[4px] bg-red50">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size : "12"}
        height={size ? size : "12"}
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M2 2L10 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M2 10L10 2"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export const AlpabetIcon = ({
  size,
  alphabet,
}: {
  size?: number;
  alphabet: string;
}) => {
  return (
    <div
      className={`bg-gray15 rounded-[6px] p-[3px] w-[18px] h-[18px] text-center flex items-center justify-center`}
    >
      <span
        className={`w-[13px] justify-center  text-[13px] font-semibold font-['Pretendard'] leading-[13px] text-gray40 text-center`}
      >
        {alphabet}
      </span>
    </div>
  );
};

export const VoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2.92969 11.5984H21.063V19.7318C21.063 20.7627 20.2273 21.5984 19.1964 21.5984H4.79635C3.76542 21.5984 2.92969 20.7627 2.92969 19.7318V11.5984Z"
        fill="#3D8AEF"
      />
      <path
        d="M4.89047 6.78576C5.31561 5.82075 6.26723 5.19841 7.31766 5.19844L16.5743 5.19867C17.605 5.1987 18.5426 5.79828 18.9789 6.73651L20.8988 10.8648C21.4332 12.014 20.5983 13.3318 19.3357 13.3318H4.65687C3.40952 13.3318 2.5744 12.0429 3.07924 10.897L4.89047 6.78576Z"
        fill="#70A9F5"
      />
      <path
        d="M7.59635 8.93177C7.59635 8.56358 7.89483 8.2651 8.26302 8.2651H15.7297C16.0979 8.2651 16.3964 8.56358 16.3964 8.93177C16.3964 9.29996 16.0979 9.59844 15.7297 9.59844H8.26302C7.89483 9.59844 7.59635 9.29996 7.59635 8.93177Z"
        fill="#2867DA"
      />
      <path
        d="M8.26302 3.0651C8.26302 2.69691 8.5615 2.39844 8.92969 2.39844H15.063C15.4312 2.39844 15.7297 2.69691 15.7297 3.0651V8.93177H8.26302V3.0651Z"
        fill="#E9EDF1"
      />
    </svg>
  );
};
