import { useEffect, useState } from "react";

const usePriority = (seconds: number) => {
    const [priority, setPriority] = useState<boolean>(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setPriority(true);
        }, seconds * 1000);
        return () => clearTimeout(timer);
    }, []);
    return priority;
}

export default usePriority;
