import DividerButton from "components/common/divider/button";
import { StrategySkeleton } from "components/skeleton/main";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState, useEffect, useRef } from "react";
import { TYPO } from "styles/Typo";
import { useStrategyMainDataVer2 } from "utils/clientAPIs/stragey";
import {
  SERVICE_STRATEGY_CODES,
  STRATEGY_META,
  STRATEGY_TYPE,
} from "utils/constant/STATUS";
import { toDetailPage } from "utils/navigateToDetailPage";
import useHandleImageError from "utils/useHandleImageError";

function Strategies({}: {}) {
  const [activeStrategy, setActiveStrategy] = useState<number>(0);
  const router = useRouter();
  const [animation, setAnimation] = useState<string>("");
  const [data, isLoadng] = useStrategyMainDataVer2();
  const rCodes = SERVICE_STRATEGY_CODES;
  const [intervalTimer, setTimer] = useState<any>(null);
  const [timeoutTimer, setTimeoutTimer] = useState<any>(null);
  const refs = useRef<any[]>([]);
  const [widths, setWidths] = useState<number[]>([]);

  const iconMeta: any = {
    km7: {
      icon: KM7Icon,
      bgColor: "bg-risk-bg-yellow",
    },
    fs7: {
      icon: FS7Icon,
      bgColor: "bg-blue5",
    },
    rs3: {
      icon: RS3Icon,
      bgColor: "bg-risk-bg-green",
    },
  };
  useEffect(() => {
    if (!data) return;
    // refs 배열 길이 맞추기
    refs.current = refs.current.slice(0, rCodes.length);

    // mount 후 한 번만 width 측정
    setTimeout(() => {
      const measured = refs.current.map((el: any) => el?.clientWidth || 0);
      setWidths(measured);
    }, 100);
  }, [data]);

  const stopInterval = () => {
    clearTimeout(intervalTimer);
  };
  const clearTimeoutTimer = () => {
    clearTimeout(timeoutTimer);
  };

  const startInterval = () => {
    let timer = setInterval(() => {
      setAnimation("fadeOut");
      clearTimeoutTimer();
      let outTimer = setTimeout(() => {
        setAnimation("fadeIn");
        setActiveStrategy((prev) => (prev + 1) % rCodes.length);
      }, 200);
      setTimeoutTimer(outTimer);
    }, 3000);
    setTimer(timer);
  };

  useEffect(() => {
    if (!data) return;
    startInterval();
    return () => {
      stopInterval();
      clearTimeoutTimer();
    };
  }, [data]);

  const ListTemp = ({ asset }: { asset: any }) => {
    return (
      <div
        className={`px-6 py-2 gap-3 items-center flex cursor-pointer ${
          animation === "fadeOut"
            ? "fade-out-strategy"
            : animation === "fadeIn"
            ? "fade-in-strategy"
            : ""
        }`}
        onClick={() => {
          toDetailPage(router, asset.ITEM_CD_DL);
        }}
      >
        <div className="w-[24px] h-[24px]">
          <Image
            unoptimized
            quality={100}
            alt="img"
            height={24}
            width={24}
            src={`https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png`}
            style={{
              borderRadius: "100%",
              objectFit: "cover",
              width: "24px",
              height: "24px",
            }}
            // onError={(event) => useHandleImageError(event, HR_ITEM_NM)}
            key={`image-${asset.ITEM_CD_DL}`}
            priority={true}
          />
        </div>
        <span className={`grow ${TYPO.TYPO7_MD} text-gray80 truncate`}>
          {router.locale === "ko" ? asset.ITEM_KR_NM : asset.ITEM_ENG_NM}
        </span>
        <span
          className={`st-spec-asset-return ${TYPO.TYPO7_MD} ${
            asset?.ADJ_CLOSE_CHG > 0
              ? "text-red40"
              : !asset?.ADJ_CLOSE_CHG || asset?.ADJ_CLOSE_CHG === 0
              ? "text-gray50"
              : "text-blue40"
          }`}
        >
          {asset?.ADJ_CLOSE_CHG > 0 ? "+" : ""}
          {asset?.ADJ_CLOSE_CHG ? asset?.ADJ_CLOSE_CHG?.toFixed(1) : 0}%
        </span>
      </div>
    );
  };

  if (isLoadng || !data) {
    return (
      <div className="bg-white">
        <StrategySkeleton />
      </div>
    );
  }
  return (
    <div className="bg-white">
      <div className="flex flex-col w-full gap-2">
        <div className="px-6 pt-6">
          <span className={`${TYPO.TYPO4_SB} text-gray80`}>
            따라하면 돈 버는 투자 전략
          </span>
        </div>
        <div className="py-2 flex flex-col ">
          <div className="px-6 py-2 flex items-center gap-3">
            {rCodes?.map((code, index) => {
              const ret = data?.[code]?.ret;
              return (
                <div
                  className="flex gap-2 items-center overflow-hidden w-fit"
                  key={index}
                >
                  <div
                    className={`w-[40px] h-[40px] rounded-[14px] overflow-hidden flex items-center justify-center shrink-0 cursor-pointer ${
                      activeStrategy === index
                        ? "border-[2px] border-blue40"
                        : ""
                    } ${iconMeta?.[code]?.bgColor}`}
                    onClick={() => {
                      stopInterval();
                      setAnimation("fadeOut");
                      clearTimeoutTimer();
                      let outTimer = setTimeout(() => {
                        setAnimation("fadeIn");
                        setActiveStrategy(index);
                        startInterval();
                      }, 200);
                      setTimeoutTimer(outTimer);
                    }}
                  >
                    {iconMeta?.[code]?.icon()}
                  </div>
                  <div
                    className="relative  h-[42.5px]  overflow-hidden w-fit  "
                    style={{
                      width:
                        activeStrategy === index
                          ? widths[index] || "120px"
                          : "0px",
                      transform:
                        activeStrategy === index ? "scale(1)" : "scale(0.8)",
                      opacity: activeStrategy === index ? 1 : 0,
                      transition: "all 0.6s ease-in-out",
                    }}
                  >
                    <div
                      className="flex flex-col h-[42.5px] w-fit "
                      ref={(el: HTMLDivElement | null) => {
                        (refs as any).current[index] = el;
                      }}
                    >
                      <span
                        className={`${TYPO.TYPO7_MD} text-gray80 whitespace-nowrap w-fit`}
                      >
                        {STRATEGY_META[code]?.name}
                      </span>
                      <div className="flex items-center gap-1">
                        <span
                          className={`${TYPO.TYPO8_RG} text-gray60 whitespace-nowrap w-fit`}
                        >
                          이번달
                        </span>
                        <span
                          className={`${TYPO.TYPO8_RG} ${
                            ret
                              ? Number(ret) > 0
                                ? "text-rose-500"
                                : Number(ret) === 0
                                ? "text-gray60"
                                : "text-blue-500"
                              : "text-gray60"
                          }  whitespace-nowrap`}
                        >
                          {ret ? (Number(ret) > 0 ? "+" : "") : ""}
                          {!ret
                            ? "0%"
                            : Number(ret).toFixed(1) === "0.0"
                            ? "0%"
                            : `${Number(ret).toFixed(1)}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="py-2 flex flex-col gap-2">
            {data?.[rCodes[activeStrategy]]?.assets?.map(
              (asset: any, index: number) => (
                <ListTemp asset={asset} key={index} />
              )
            )}
          </div>
        </div>
      </div>
      <DividerButton
        text={`더 보기`}
        onClick={() => {
          router.push(`/strategy/${rCodes?.[activeStrategy]}?type=holdings`);
        }}
      />
    </div>
  );
}

const KM7Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M28.9786 7.99976H10.8036C9.80803 7.99976 9.00098 8.80681 9.00098 9.80236V30.4057C9.00098 31.4013 9.80803 32.2083 10.8036 32.2083H28.9786C29.9742 32.2083 30.7812 31.4013 30.7812 30.4057V9.80236C30.7812 8.80681 29.9742 7.99976 28.9786 7.99976Z"
        fill="#DAE1E9"
      />
      <path
        d="M15.5118 11.4412H14.0667C13.861 11.4412 13.6943 11.6079 13.6943 11.8136V13.2587C13.6943 13.4644 13.861 13.6311 14.0667 13.6311H15.5118C15.7175 13.6311 15.8842 13.4644 15.8842 13.2587V11.8136C15.8842 11.6079 15.7175 11.4412 15.5118 11.4412Z"
        fill="#9FADBC"
      />
      <path
        d="M20.547 11.4412H19.1019C18.8962 11.4412 18.7295 11.6079 18.7295 11.8136V13.2587C18.7295 13.4644 18.8962 13.6311 19.1019 13.6311H20.547C20.7527 13.6311 20.9194 13.4644 20.9194 13.2587V11.8136C20.9194 11.6079 20.7527 11.4412 20.547 11.4412Z"
        fill="#9FADBC"
      />
      <path
        d="M25.7015 11.4412H24.2564C24.0508 11.4412 23.884 11.6079 23.884 11.8136V13.2587C23.884 13.4644 24.0508 13.6311 24.2564 13.6311H25.7015C25.9072 13.6311 26.074 13.4644 26.074 13.2587V11.8136C26.074 11.6079 25.9072 11.4412 25.7015 11.4412Z"
        fill="#9FADBC"
      />
      <path
        d="M15.5118 16.4913H14.0667C13.861 16.4913 13.6943 16.6581 13.6943 16.8637V18.3088C13.6943 18.5145 13.861 18.6812 14.0667 18.6812H15.5118C15.7175 18.6812 15.8842 18.5145 15.8842 18.3088V16.8637C15.8842 16.6581 15.7175 16.4913 15.5118 16.4913Z"
        fill="#9FADBC"
      />
      <path
        d="M20.547 16.4913H19.1019C18.8962 16.4913 18.7295 16.6581 18.7295 16.8637V18.3088C18.7295 18.5145 18.8962 18.6812 19.1019 18.6812H20.547C20.7527 18.6812 20.9194 18.5145 20.9194 18.3088V16.8637C20.9194 16.6581 20.7527 16.4913 20.547 16.4913Z"
        fill="#9FADBC"
      />
      <path
        d="M18.8184 28.305H20.9041C21.4255 28.305 21.8426 28.7221 21.8426 29.2435V32.1932H17.8799V29.2435C17.8799 28.7221 18.297 28.305 18.8184 28.305Z"
        fill="#8194A7"
      />
      <path
        d="M25.7015 16.4913H24.2564C24.0508 16.4913 23.884 16.6581 23.884 16.8637V18.3088C23.884 18.5145 24.0508 18.6812 24.2564 18.6812H25.7015C25.9072 18.6812 26.074 18.5145 26.074 18.3088V16.8637C26.074 16.6581 25.9072 16.4913 25.7015 16.4913Z"
        fill="#9FADBC"
      />
      <path
        d="M15.5118 21.5418H14.0667C13.861 21.5418 13.6943 21.7085 13.6943 21.9142V23.3593C13.6943 23.565 13.861 23.7317 14.0667 23.7317H15.5118C15.7175 23.7317 15.8842 23.565 15.8842 23.3593V21.9142C15.8842 21.7085 15.7175 21.5418 15.5118 21.5418Z"
        fill="#9FADBC"
      />
      <path
        d="M20.547 21.5418H19.1019C18.8962 21.5418 18.7295 21.7085 18.7295 21.9142V23.3593C18.7295 23.565 18.8962 23.7317 19.1019 23.7317H20.547C20.7527 23.7317 20.9194 23.565 20.9194 23.3593V21.9142C20.9194 21.7085 20.7527 21.5418 20.547 21.5418Z"
        fill="#9FADBC"
      />
      <path
        d="M25.7015 21.5418H24.2564C24.0508 21.5418 23.884 21.7085 23.884 21.9142V23.3593C23.884 23.565 24.0508 23.7317 24.2564 23.7317H25.7015C25.9072 23.7317 26.074 23.565 26.074 23.3593V21.9142C26.074 21.7085 25.9072 21.5418 25.7015 21.5418Z"
        fill="#9FADBC"
      />
      <path
        d="M23.9129 23.9848L24.0023 23.9252C25.4026 24.1934 26.9818 24.2381 28.3673 23.8954L28.4715 23.9401C28.4715 24.0593 28.5907 24.1338 28.6801 24.2083C30.7211 26.0854 33.939 28.3051 32.7621 31.523C31.7788 34.1747 27.7267 34.3833 25.3431 34.2939C22.9594 34.2045 20.114 33.698 19.4585 31.1356C19.0414 29.5416 19.533 28.4839 20.5162 27.2474C21.4995 26.0109 22.7658 24.9829 23.9427 23.9848H23.9129Z"
        fill="#FFC633"
      />
      <path
        d="M28.3372 23.2101L28.2478 23.2995C26.9815 23.4634 25.7897 23.6124 24.5085 23.3293L24.0169 23.2995L23.9573 23.2101L22.691 21.5267C22.5569 21.1841 22.7804 20.9755 23.0784 20.8563C23.704 20.5882 24.0765 20.901 24.6575 20.8563C25.1044 20.8265 25.4769 20.5286 25.9685 20.4988C26.624 20.4541 26.7581 20.6329 27.3093 20.7818C28.0094 20.9904 28.5905 20.5733 29.2311 20.8414C29.529 20.9606 29.7823 21.2288 29.5886 21.5714L28.3223 23.2101H28.3372Z"
        fill="#FFC633"
      />
      <path
        d="M28.3372 23.2101C28.6947 23.2548 28.7698 23.7464 28.4718 23.9252C28.3079 24.0295 27.9355 24.0742 27.7269 24.104C26.7437 24.253 25.6115 24.2381 24.6282 24.104C24.4644 24.0742 24.0621 24.0295 23.9281 23.9699C23.6301 23.8358 23.6599 23.2846 23.9727 23.2101C24.0472 23.1952 24.226 23.2101 24.3154 23.2101C25.0156 23.2697 25.7605 23.4485 26.4755 23.3889C27.0268 23.4187 27.8307 23.1506 28.3372 23.2101Z"
        fill="#8F4A34"
      />
    </svg>
  );
};
const FS7Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M33.1883 25.5774L29.1907 18.6948V13.3136H28.1404V18.7117L24.1598 25.5774L25.0745 26.1004L28.6655 19.8925L32.2736 26.1004L33.1883 25.5774Z"
        fill="#D1D6DD"
      />
      <path
        d="M15.961 25.5774L11.9634 18.6948V13.3136H10.9302V18.7117L6.94951 25.5774L7.84728 26.1004L11.4553 19.8925L15.0463 26.1004L15.961 25.5774Z"
        fill="#D1D6DD"
      />
      <path
        d="M32.29 11.7787H21.0086V9.01215C21.0086 8.45547 20.5512 8 19.9923 8C19.4333 8 18.9759 8.45547 18.9759 9.01215V11.7787H7.69456C7.13557 11.7787 6.67822 12.2341 6.67822 12.7908C6.67822 13.3475 7.13557 13.803 7.69456 13.803H18.9759V30.1323H16.4012C15.8422 30.1323 15.3849 30.5877 15.3849 31.1444V31.9879C15.3849 32.5445 15.8422 33 16.4012 33H23.6003C24.1593 33 24.6166 32.5445 24.6166 31.9879V31.1444C24.6166 30.5877 24.1593 30.1323 23.6003 30.1323H21.0255V13.8198H32.3069C32.8659 13.8198 33.3232 13.3644 33.3232 12.8077C33.3232 12.251 32.8659 11.7955 32.3069 11.7955L32.29 11.7787Z"
        fill="#A6B4C7"
      />
      <path
        d="M19.9758 14.6127C20.9768 14.6127 21.7883 13.8046 21.7883 12.8077C21.7883 11.8109 20.9768 11.0027 19.9758 11.0027C18.9748 11.0027 18.1633 11.8109 18.1633 12.8077C18.1633 13.8046 18.9748 14.6127 19.9758 14.6127Z"
        fill="#505967"
      />
      <path
        d="M23.5995 24.8184H33.6273C33.8306 24.8184 34 24.9871 34 25.1896C34 26.5053 32.9328 27.5681 31.6116 27.5681H25.6152C24.294 27.5681 23.2268 26.5053 23.2268 25.1896C23.2268 24.9871 23.3962 24.8184 23.5995 24.8184Z"
        fill="#505967"
      />
      <path
        d="M6.37266 24.8184H16.4005C16.6038 24.8184 16.7732 24.9871 16.7732 25.1896C16.7732 26.5053 15.706 27.5681 14.3848 27.5681H8.3884C7.06716 27.5681 6 26.5053 6 25.1896C6 24.9871 6.16939 24.8184 6.37266 24.8184Z"
        fill="#505967"
      />
    </svg>
  );
};

const RS3Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2825 23.8572C20.6699 23.9089 20.942 24.2647 20.8904 24.6521C20.7425 25.761 20.7464 27.4667 21.2174 28.9559C21.6847 30.4334 22.5616 31.5754 24.1012 31.8833C24.4844 31.96 24.7329 32.3327 24.6563 32.7159C24.5796 33.099 24.2069 33.3475 23.8237 33.2709C21.59 32.8241 20.4229 31.1361 19.8682 29.3826C19.3173 27.6407 19.3211 25.7145 19.4877 24.465C19.5394 24.0777 19.8952 23.8056 20.2825 23.8572Z"
        fill="#25B16A"
      />
      <path
        d="M10.1059 16.0591C9.37429 13.1303 12.6543 12.4234 13.9122 12.7378C13.5977 11.48 14.2517 8.25293 17.1804 8.98459C20.9554 9.92766 20.3255 15.588 20.0111 18.8897C16.7093 19.2042 11.049 19.834 10.1059 16.0591Z"
        fill="#25B16A"
      />
      <path
        d="M29.8948 21.7165C30.6264 24.6453 27.3464 25.3522 26.0886 25.0378C26.403 26.2956 25.7491 29.5226 22.8203 28.791C19.0454 27.8479 19.6752 22.1876 19.9897 18.8859C23.2914 18.5714 28.9517 17.9416 29.8948 21.7165Z"
        fill="#25B16A"
      />
      <path
        d="M17.1811 28.7928C14.2524 29.5245 13.5454 26.2444 13.8599 24.9866C12.6021 25.301 9.375 24.6471 10.1067 21.7183C11.0497 17.9434 16.71 18.5732 20.0118 18.8877C20.3262 22.1894 20.9561 27.8497 17.1811 28.7928Z"
        fill="#68C76F"
      />
      <path
        d="M22.8189 8.98314C25.7476 8.25148 26.4546 11.5315 26.1401 12.7894C27.3979 12.4749 30.625 13.1288 29.8933 16.0576C28.9503 19.8326 23.29 19.2027 19.9882 18.8883C19.6738 15.5865 19.0439 9.92621 22.8189 8.98314Z"
        fill="#68C76F"
      />
    </svg>
  );
};

export default Strategies;
