import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useMainStockList, {
  useMainIndexList,
} from "utils/clientAPIs/MainStockList";
import Strategy from "./Strategy";
import { StockListSkeleton } from "components/skeleton/main";
import StockList from "./StockList";
import { useTranslation } from "next-i18next";
import CustomToast, {
  KbPasswordReAuthToast,
} from "components/atoms/CustomToast";
import { useKbPassword } from "utils/kb/password";
import { passwordExpiredToastState } from "states/KbState";
import { useRecoilState } from "recoil";
import { useKBGlobalState } from "contexts/KBContext";
import MainDiscussIndex from "./discuss/Index";
import OngoingDiscussModal from "components/modals/OngoingDiscussModal";
import { useIsUserVote, useOngoingDiscuss } from "utils/clientAPIs/discuss";
import HighScoreList from "./explore/HighScoreList";
import IndexList from "./explore/IndexList";
import Strategies from "./explore/Strategies";
import HighMomentum from "./explore/HighMomentum";
import HotTopicList from "./explore/HotTopicList";

function StockLIstIndex({ isMobile }: { isMobile: boolean }) {
  const router = useRouter();
  const [passwordExpiredToast, setPasswordExpiredToast] = useRecoilState(
    passwordExpiredToastState
  );
  const { mainScreenPasswordSuccess, setMainScreenPasswordSuccess } =
    useKBGlobalState();
  const { t } = useTranslation("main");
  // const [koreanData, koreanIndex, isKoreanLoading] = useMainStockList({
  //   loc: "korea",
  // });
  // const [usData, usIndex, isUsLoading] = useMainStockList({ loc: "us" });
  // const [cryptoData, _, isCryptoLoading] = useMainStockList({ loc: undefined });

  const [indexList, indexLoading] = useMainIndexList();

  const [reAuthPassword, setReAuthPassword] = useState(false);
  const [
    encryptedPassword,
    PasswordScreen,
    inPasswordMode,
    load,
    getPasswordFetch,
  ] = useKbPassword(isMobile, false);
  const loading = indexLoading; //isKoreanLoading || isUsLoading;|| isCryptoLoading;

  const [discussList, isLoading] = useOngoingDiscuss();
  const [isUserVote, isUserVoteLoading] = useIsUserVote({
    discussId: discussList?.[0]?.id,
  });

  const [discussModalOpen, setDiscussModalOpen] = useState(false);
  const targetDiscuss =
    discussList?.filter((discuss: any) => !discuss?.isClosed)?.[0] || null;

  useEffect(() => {
    if (!targetDiscuss) return;
    if (!isLoading) return;
    if (isUserVoteLoading) return;

    const discussModal = localStorage.getItem(
      `discussModal:${targetDiscuss.id}`
    );

    // + 투표 한 사람이면 안띄우기
    const userChoice = isUserVote?.result;
    if (discussModal && new Date(discussModal) > new Date())
      setDiscussModalOpen(false);
    else if (userChoice) setDiscussModalOpen(false);
    else {
      setDiscussModalOpen(true);
    }
  }, [targetDiscuss, isUserVoteLoading, isLoading]);

  if (reAuthPassword && inPasswordMode) {
    return (
      <PasswordScreen
        backHandler={() => {
          setReAuthPassword(false);
        }}
      />
    );
  }

  return (
    <div>
      <MainDiscussIndex isMobile={isMobile} />
      <IndexList indexList={[...indexList]} />
      <HighScoreList />
      <Strategies />
      <HighMomentum />
      {/* <Strategy isMobile={isMobile} /> */}
      <HotTopicList isMobile={isMobile} />
      {loading && (
        <div>
          <StockListSkeleton />
          <StockListSkeleton />
        </div>
      )}
      {/* {!loading && (
        <StockList
          cat={"korean"}
          name={t("korean")}
          stocklist={[...koreanData]}
          indexList={[...koreanIndex]}
        />
      )}
      {!loading && (
        <StockList
          cat={"us"}
          name={t("us")}
          stocklist={[...usData]}
          indexList={[...usIndex]}
        />
      )}
      {!loading && (
        <StockList
          cat={"crypto"}
          name={t("crypto")}
          stocklist={[...cryptoData]}
        />
      )} */}

      {discussModalOpen && (
        <OngoingDiscussModal
          targetData={targetDiscuss}
          setModalOpen={setDiscussModalOpen}
          isModalOpen={discussModalOpen}
        />
      )}
      <KbPasswordReAuthToast
        isVisible={passwordExpiredToast}
        onClose={() => setPasswordExpiredToast(false)}
        width={300}
        buttonHanlder={() => {
          setReAuthPassword(true);
          setPasswordExpiredToast(false);
        }}
      />
      <CustomToast
        isVisible={mainScreenPasswordSuccess}
        onClose={() => setMainScreenPasswordSuccess(false)}
        message={"KB증권 계좌로 인증되었어요"}
        width={270}
        duration={1500}
        successIconFill={"#34BB7A"}
      />
    </div>
  );
}

export default StockLIstIndex;
