import BottomFloatingModal from "components/common/Modal/BottomFloatingModal";
import useModalClickOutside from "utils/useModalClickOutside";
import Button from "components/common/buttons/buttons";
import { useRouter } from "next/router";
import OngoingDiscuss from "components/templates/common/Discuss/List/OngoingDiscuss";
import { useSession } from "next-auth/react";

function OngoingDiscussModal({
  setModalOpen,
  isModalOpen,
  targetData,
}: {
  isModalOpen: boolean;
  setModalOpen: any;
  targetData: any;
}) {
  const router = useRouter();
  const { data: session }: any = useSession();
  const modalRef = useModalClickOutside(setModalOpen);

  return (
    <BottomFloatingModal isOpen={isModalOpen} modalRef={modalRef}>
      <div className="flex flex-col gap-5 w-full py-2">
        <div>
          <OngoingDiscuss
            data={targetData}
            clickable={false}
            addClassName="!pb-0"
          />
        </div>
        <div className="px-6 pb-6 flex items-center gap-2">
          <Button
            type="tertiary"
            clickHandler={() => {
              setModalOpen(false);
              const tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() + 1);
              localStorage.setItem(
                `discussModal:${targetData.id}`,
                tomorrow.toISOString()
              );
              setModalOpen(false);
            }}
            size="xl"
            text="닫기"
            width="w-full"
          />
          <Button
            type="primary"
            clickHandler={() => {
              if (!session) {
                router.push("/login");
                return;
              }
              setModalOpen(false);
              router.push(`/discuss/${targetData.id}`);
            }}
            size="xl"
            text="투표하러 가기"
            width="w-full"
          />
        </div>
      </div>
    </BottomFloatingModal>
  );
}

export default OngoingDiscussModal;
