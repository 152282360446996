import { Forward } from "components/common/Icons";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";
import { AlpabetIcon, OIcon, XIcon } from "../../Discuss/Icons";
import { useSession } from "next-auth/react";

function OngoingDiscussMain({
  data,
  isMobile,
}: {
  data: any;
  isMobile: boolean;
}) {
  const router = useRouter();
  const isOXOption = data.agendaType === "binary";
  const { data: session }: any = useSession();

  return (
    <div className="bg-gray2 rounded-[20px] flex flex-col gap-4">
      <div className="flex items-center justify-between px-5 pt-5 gap-2 break-keep">
        <div className=" flex flex-col gap-2">
          {/* {isOXOption ? (
          <div className="flex gap-1 items-center">
            <OIcon size={12} />
            <XIcon size={12} />
          </div>
        ) : (
          <div className="flex gap-1 items-center">
            <AlpabetIcon size={12} alphabet="A" />
            <AlpabetIcon size={12} alphabet="B" />
          </div>
        )} */}
          <span className={`${TYPO.TYPO7_1_MD} text-gray60`}>
            {`${data.totalVotes}명 투표중!`}
          </span>
          <span
            className={`whitespace-pre-line ${
              TYPO.TYPO5_SB
            } text-gray80 break-words break-keep ${
              isMobile ? "max-w-[200px]" : "max-w-[500px]"
            }`}
          >
            {data.title}
          </span>
        </div>
        <VoteIcon />
      </div>

      <div
        className="flex items-center justify-center gap-2 border-t border-t-gray20 px-6 py-[14px] cursor-pointer"
        onClick={() => {
          if (!session) {
            router.push("/login");
            return;
          }
          router.push(`/discuss/${data.id}`);
        }}
      >
        <span className={`${TYPO.TYPO5_1_MD} text-blue40`}>투표하러 가기</span>
        <Forward fill="blue40" width={16} height={16} />
      </div>
    </div>
  );
}

export const VoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M5.85938 23.1969H42.126V39.4635C42.126 41.5254 40.4546 43.1969 38.3927 43.1969H9.59271C7.53084 43.1969 5.85938 41.5254 5.85938 39.4635V23.1969Z"
        fill="#3D8AEF"
      />
      <path
        d="M9.78094 13.5715C10.6312 11.6415 12.5345 10.3968 14.6353 10.3969L33.1485 10.3973C35.2101 10.3974 37.0852 11.5966 37.9578 13.473L41.7975 21.7296C42.8664 24.028 41.1965 26.6635 38.6714 26.6635H9.31374C6.81905 26.6635 5.1488 24.0858 6.15849 21.794L9.78094 13.5715Z"
        fill="#70A9F5"
      />
      <path
        d="M15.1927 17.8635C15.1927 17.1272 15.7897 16.5302 16.526 16.5302H31.4594C32.1958 16.5302 32.7927 17.1272 32.7927 17.8635C32.7927 18.5999 32.1958 19.1969 31.4594 19.1969H16.526C15.7897 19.1969 15.1927 18.5999 15.1927 17.8635Z"
        fill="#2867DA"
      />
      <path
        d="M16.526 6.13021C16.526 5.39383 17.123 4.79688 17.8594 4.79688H30.126C30.8624 4.79688 31.4594 5.39383 31.4594 6.13021V17.8635H16.526V6.13021Z"
        fill="#E3E8EE"
      />
    </svg>
  );
};
export default OngoingDiscussMain;
